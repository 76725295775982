

<!--
    <ng-container *ngSwitch="stack.stackType">
        <app-card *ngSwitchDefault [viewMode]="viewMode"  [card]="stack.cards[0]"></app-card>
        <app-carousel  *ngSwitchCase="STACK_TYPE.SINGLE" [stack]="stack" [viewMode]="viewMode" [submitted]="submitted">
        </app-carousel>
        <app-card *ngSwitchCase="STACK_TYPE.SINGLE" [viewMode]="viewMode"  [card]="stack.cards[0]"></app-card>
    </ng-container>
-->
  <div #wrapper class="stack-wrapper" [class.stack-wrapper--side-menu]="wrapper.offsetWidth > 600">

    <div *ngIf="wrapper.offsetWidth > 600" class="left-menu">
        <ng-container *ngIf="stack.cards.length > 1">
          <div class="page-button-wrapper" *ngFor="let card of stack.cards; let i = index">
            <button (click)="menuIndex=i; menuNavigation(i)" mat-flat-button>
              {{stack.cards[i].header?.title || 'Page #' + (i+1) }}
            </button>
            <div *ngIf="showPageActions && stack.cards.length > 1" class="page-actions">
              <button (click)="moveCardUp(i)" class="icon-button">
                <mat-icon>expand_less</mat-icon>
              </button>
              <button (click)="moveCardDown(i)" class="icon-button">
                <mat-icon>expand_more</mat-icon>
              </button>
              <button (click)="deleteCard(i)" class="icon-button">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <div *ngIf="(viewStrat$ | async) !== ViewModeStrategy.Display" class="options-menu-button">
            <button mat-flat-button style="width: 100%" (click)="addCard()">
              <mat-icon>add</mat-icon>
              New Page
            </button>
            <button class="icon-button" (click)="showPageActions = !showPageActions">
              <mat-icon>{{ showPageActions ? 'check' : 'edit' }}</mat-icon>
            </button>
          </div>

        </ng-container>
        <ng-container *ngIf="stack.cards.length === 1 && (viewStrat$ | async) !== ViewModeStrategy.Display">
          <button id="create-menu-button" mat-flat-button (click)="addCard()">
            <mat-icon [svgIcon]="'menu_add'">
            </mat-icon>
            Add Page
          </button>
        </ng-container>
    </div>
    <div class="main">
      <div class="sidebar-wrapper">
        <div class="sidebar" [class.sidebar--open]="menuOpen$ | async">
          <div class="stack-actions">
            <button *ngIf="stack.stackType === STACK_TYPE.MENU" mat-icon-button [matMenuTriggerFor]="stack_actions">
              <mat-icon>more_verti</mat-icon>
            </button>
            <button mat-icon-button (click)="closeStackMenu()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="pages">
            <div *ngFor="let card of stack.cards; let i = index" class="page-button-wrapper">
              <button mat-button (click)="menuIndex=i; menuNavigation(i)">
                {{stack.cards[i].header?.title || 'Page #' + (i+1) }}
              </button>
              <div *ngIf="showPageActions && stack.cards.length > 1" class="page-actions">
                <button (click)="moveCardUp(i)" class="icon-button">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button (click)="moveCardDown(i)" class="icon-button">
                  <mat-icon>expand_more</mat-icon>
                </button>
                <button (click)="deleteCard(i)" class="icon-button">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>

            <div *ngIf="(viewStrat$ | async) !== ViewModeStrategy.Display" class="options-menu-button">
              <button mat-flat-button style="width: 100%" (click)="addCard()">
                <mat-icon>add</mat-icon>
                New Page
              </button>
              <button class="icon-button" (click)="showPageActions = !showPageActions">
                <mat-icon>{{ showPageActions ? 'check' : 'edit' }}</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <app-card
          #card
          (edit)="onEdit()"
          (navigateTo)="onNavigateTo($event)"
          (clone)="onClone()"
          (copyLink)="onCopyLink()"
          (share)="shareStack()"
          (send)="sendContent()"
          (createStackMenu)="null"
          (deleteStack)="deleteStack()"
          [card]="stack.cards[menuIndex]"
          [isLoading]="isLoading"
          [loadSpotify]="loadSpotify"
          [class.app-card-fullscreen]="card.elementWidth ? card.elementWidth > 800 : false"
          [class.menu-card]="menuOpen$ | async"
        ></app-card>    </div>
      <div class="creater-buttons-wrapper">
        <ng-container *ngIf="!hideCreaterButtons && (viewStrat$ | async) === ViewModeStrategy.Editor">
          <div class="left">
            <button [color]="'primary'" mat-button (click)="onCancelEdit()">
              <mat-icon>close</mat-icon>
              Cancel
            </button>
            <button
              mat-button
              [color]="'primary'"
              *ngIf="(feedContext.feedContext$ | async)?.id !== 'my_cards' && !postingCard"
              (click)="onSendStack(stack, 'my_cards')"
            >
              Draft
              <mat-icon>save</mat-icon>
            </button>
          </div>
          <button
            *ngIf="!stack.id && (channel$ | async)?.id"
            [disabled]="postingCard"
            mat-flat-button
            (click)="onSendStack(stack, feedContext._feedContextSnapshot?.id )"
            color="primary"
            class="shadow"
          >
            <mat-icon *ngIf="!postingCard"> send </mat-icon>
            <mat-icon *ngIf="postingCard">
              <mat-spinner [diameter]="24" [strokeWidth]="3"></mat-spinner>
            </mat-icon>
            Send
          </button>
          <button
            *ngIf="stack.id"
            [disabled]="postingCard"
            mat-flat-button
            (click)="onSaveCard(stack)"
            color="primary"
            class="shadow"
          >
            <mat-icon *ngIf="!postingCard"> save </mat-icon>
            <mat-icon *ngIf="postingCard">
              <mat-spinner [diameter]="24" [strokeWidth]="3"></mat-spinner>
            </mat-icon>
            Save
          </button>
        </ng-container>



        <!--<button *ngIf="VIEW_MODE.EDIT" mat-button (click)="onAddToTemplates(stack.cards[0])">
            <mat-icon>add</mat-icon>
            Add to templates
        </button>
        -->
        <!--        <button  mat-button (click)="onAddBlankCard()" id="stack-button" color="accent">
                    <mat-icon>view_carousel</mat-icon> Carousel
            </button>-->
      </div>
    </div>
  </div>

<mat-menu #stack_actions="matMenu">
  <div>
    <button *ngIf="(headerPermissions$ | async)?.edit" mat-menu-item (click)="onEdit()">
      <mat-icon>edit</mat-icon>
      Edit
    </button>
    <button *ngIf="(headerPermissions$ | async)?.share" mat-menu-item (click)="shareStack()">
      <mat-icon>share</mat-icon>
      Share
    </button>
    <button *ngIf="(headerPermissions$ | async)?.share" mat-menu-item (click)="onCopyLink()">
      <mat-icon>content_copy</mat-icon>
      Link
    </button>
    <button *ngIf="(headerPermissions$ | async)?.send" mat-menu-item (click)="sendContent()">
      <mat-icon>send</mat-icon>
      Send
    </button>
    <button  *ngIf="(headerPermissions$ | async)?.clone" mat-menu-item (click)="clone.emit()">
      <mat-icon>fork_right</mat-icon>
      Remix
    </button>
    <button
      *ngIf="(headerPermissions$ | async)?.delete"
      mat-menu-item
      (click)="deleteStack()"
    >
      <mat-icon>
        delete
      </mat-icon>
      <ng-container *ngIf="!(feedContext?.feedContext$ | async)">
        Delete
      </ng-container>
      <ng-container *ngIf="feedContext?.feedContext$ | async">
        {{(channelService.selectedChannel$ | async)?.id === 'my_cards' ? 'Delete' : 'Remove'}}
      </ng-container>

    </button>
  </div>
</mat-menu>
