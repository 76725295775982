
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import {VIEW_MODE} from "../../../enum";
import {environment} from "../../../../environments/environment";
import {CardNavigationService} from "@app/services/card-navigation/card-navigation.service";
import {ChannelsService} from "@app/services/channels/channels.service";
import {CardHeader, CardHeaderActions} from "@mrbeany/stacks_shared/lib/models/card-elements/cardheader";
import {isChannelDTO, isNetworkDTO} from "@app/services/feed/feed.service";
import {getHeaderActions} from "@app/card.components/card/card.component";
import {AuthenticationService} from "@app/authentication/authentication.service";
import {map, tap} from "rxjs/operators";
import {FeedContext, FeedContextService} from "@app/services/feed-context.service";
import {BehaviorSubject, combineLatest, Subscription} from "rxjs";
import {User} from "@app/authentication/user";
import {StackService} from "@app/card.components/stack/stack.service";
import {ViewModeStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";
import {readAndCompressFiles} from "@app/services/upload.service";
import {DialogRef} from "@angular/cdk/dialog";
import {DialogService} from "@app/services/dialog.service";
import {ConfirmationDialogComponent} from "@app/modules/confirmation/confirmation-dialog/confirmation-dialog.component";
import {Stack} from "@mrbeany/stacks_shared";
import {STACK_TYPE} from "@mrbeany/stacks_shared/lib/models/stack";
import {AppService} from "@app/services/navbar/app.service";

@Component({
  selector: "app-cardheader",
  templateUrl: "./cardheader.component.html",
  styleUrls: ["./cardheader.component.scss"],
})
export class CardheaderComponent {
  @Input() set header(header: CardHeader){
    this._header.next(header)
  };
  protected readonly STACK_TYPE = STACK_TYPE;


  _header = new BehaviorSubject<CardHeader | undefined>(undefined);
  header$ = this._header.asObservable();
  headerPermissions$ = combineLatest([this.header$, this.authenticationService.user$]).pipe(
    map(([header, user]) => {
      if(!header) {
        return undefined;
      }
      return getHeaderActions(
        true,
        user,
        header.username,
        this.feedContext._feedContextSnapshot
      )
    })
  );

  stack = this.stackService.stack;

  @Input() expanded = false;
  @Input() expandable = false;
  @Output() delete = new EventEmitter<string>();
  @Output() editCard = new EventEmitter<void>();
  @Output() copyCardInfoToClipboard = new EventEmitter<void>();
  @Output() sendCard = new EventEmitter<void>();
  @Output() shareCard = new EventEmitter<void>();
  @Output() clone = new EventEmitter<void>();
  @Output() copyCardLink = new EventEmitter<void>();
  @Output() createStackMenu = new EventEmitter<void>();

  VIEW_MODE = VIEW_MODE;

  canGoBackward$ = this.cardNavService.canGoBackward$;

  constructor(
    private cardNavService: CardNavigationService,
    public channelService: ChannelsService,
    private authenticationService: AuthenticationService,
    public feedContext: FeedContextService,
    private cdr: ChangeDetectorRef,
    private stackService: StackService,
    private appService: AppService,
  ) {}

  isHandset$ = this.appService.isHandset$;
  viewStrat$ = this.stackService.viewStrategy$;


  ngOnInit() {
    this.stackService.menuOpen$.subscribe();
  }

  goBack() {
    this.cardNavService.goBack();
  }

  protected readonly ViewModeStrategy = ViewModeStrategy;


  onFileSelected($event: Event) {
    // @ts-ignore
    const file = $event.target['files'][0];
    readAndCompressFiles([file]).then((compressedFiles) => {
      const images = compressedFiles
        ?.filter((file) => file instanceof Blob);
      //@ts-ignore
      blobToBase64(images[0]).then((res) => {
        //@ts-ignore
        this.header.avatarIMG = res;
        this.cdr.detectChanges();
      })
    });
  }

  openStackMenu() {
    this.stackService.openMenu();
  }
}
async function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
