import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonContainerModule} from "./button_container/button-container.module";
import {ButtonContainerComponent} from "./button_container/button-container.component";
import {CardChatModule} from "./card-chat/card-chat.module";
import {CardChatComponent} from "./card-chat/card-chat.component";
import {CardHeaderModule} from "./card-header/card-header.module";
import {CardheaderComponent} from "./card-header/cardheader.component";
import {EmailSubModule} from "./email-sub/email-sub.module";
import {EmailSubComponent} from "./email-sub/email-sub.component";
import {FileModule} from "@app/card.components/card.elements/file/file.module";
import {FileComponent} from "@app/card.components/card.elements/file/file.component";
import {GalleryModule} from "@app/card.components/card.elements/gallery/gallery.module";
import {GalleryComponent} from "@app/card.components/card.elements/gallery/gallery.component";
import {QuillModule} from "@app/card.components/card.elements/quill/quill.module";
import {QuillComponent} from "@app/card.components/card.elements/quill/quill.component";
import {SocialsModule} from "@app/card.components/card.elements/socials/socials.module";
import {SocialsComponent} from "@app/card.components/card.elements/socials/socials.component";
import {SpotifyModule} from "@app/card.components/card.elements/spotify/spotify.module";
import {SpotifyComponent} from "@app/card.components/card.elements/spotify/spotify.component";
import {TextModule} from "@app/card.components/card.elements/text/text.module";
import {TextComponent} from "@app/card.components/card.elements/text/text.component";
import {MatRippleModule} from "@angular/material/core";
import { HyperlinkComponent } from './hyperlink/hyperlink.component';
import {HyperlinkModule} from "@app/card.components/card.elements/hyperlink/hyperlink.module";
import { ProductElementComponent } from './product-element/product-element.component';
import {MatMenuModule} from "@angular/material/menu";
import {ProductElementModule} from "@app/card.components/card.elements/product-element/product-element.module";
import {PlaceholderElementModule} from "@app/core/placeholder/placeholder-element.module";



@NgModule({
  imports: [
    CommonModule,
    ButtonContainerModule,
    CardChatModule,
    CardHeaderModule,
    EmailSubModule,
    FileModule,
    GalleryModule,
    QuillModule,
    SocialsModule,
    SpotifyModule,
    TextModule,
    MatRippleModule,
    HyperlinkModule,
    MatMenuModule,
    ProductElementModule,
    PlaceholderElementModule
  ],
  exports: [
    ButtonContainerComponent,
    CardChatComponent,
    CardheaderComponent,
    EmailSubComponent,
    FileComponent,
    GalleryComponent,
    QuillComponent,
    SocialsComponent,
    SpotifyComponent,
    TextComponent,
    HyperlinkComponent,
    ProductElementComponent,
  ],
})
export class CardElementsModule {}
