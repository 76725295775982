<ng-container *ngIf="header$ | async as header">
  <div
    #card
    class="cardheader-wrapper"
    [class.cardheader-wrapper--no-avatar]="!header?.avatarIMG"
    *ngIf="(viewStrat$ | async) === ViewModeStrategy.Display"
  >
    <ng-container *ngIf="header?.avatarIMG">
      <img *ngIf="header.avatarIMG" [src]="header.avatarIMG | handleImageSrc" class="header-image" />
    </ng-container>

    <div class="cardheader-text">
      <div class="cardheader-title">
        {{ header.title }}
        {{header?.actions?.edit}}
      </div>
      <div class="carheader-subtitle">
        {{ header.username }}
      </div>
    </div>

    <div>
      <button class="button" mat-icon-button *ngIf="canGoBackward$ | async" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <button class="button" mat-icon-button *ngIf="stack?.stackType === STACK_TYPE.SINGLE" [matMenuTriggerFor]="cardheader_actions">
        <mat-icon>more_verti</mat-icon>
      </button>
      <button class="button hamburger-menu" mat-icon-button *ngIf="stack?.stackType === STACK_TYPE.MENU" (click)="openStackMenu()">
        <mat-icon
          [svgIcon]="'menu_hamburger'">
        </mat-icon>
      </button>

    </div>
  </div>

  <div #wrapper class="cardheader-wrapper" *ngIf="(viewStrat$ | async) !== ViewModeStrategy.Display">
    <img *ngIf="header.avatarIMG" [src]="header.avatarIMG | handleImageSrc" class="header-image" />
    <ng-container *ngIf="!header.avatarIMG" >
      <label
        class="header-image"
        for="headerAvatar"
      >
        <mat-icon class="image">add_photo_alternate</mat-icon>
      </label>
      <input
        type="file"
        (change)="onFileSelected($event)"
        id="headerAvatar"
        name="headerAvatar"
        accept=".jpg, .jpeg, .png, .webp"
        class="visually-hidden"
      >
    </ng-container>

    <div class="cardheader-text">
      <div class="cardheader-title">
        <input matInput type="text" placeholder="Title" [(ngModel)]="header.title" />
      </div>
      <ng-container *ngIf="(viewStrat$ | async) !== ViewModeStrategy.Wizard">
        <div class="cardheader-subtitle">
          {{ header.username }}
        </div>
      </ng-container>
      <ng-container *ngIf="(viewStrat$ | async) === ViewModeStrategy.Wizard">
        <div class="cardheader-subtitle">
          <input #usernameInput matInput type="text" type="text" placeholder="Username" [(ngModel)]="header.username">
          <mat-icon *ngIf="!usernameInput.value" [ngStyle]="{left: usernameInput.placeholder.length * 12 + 'px'}">edit</mat-icon>
        </div>
      </ng-container>
    </div>
    <button class="button hamburger-menu" mat-icon-button (click)="openStackMenu()">
      <mat-icon
        [svgIcon]="stack?.cards?.length === 1 ? 'menu_add' : 'menu_hamburger'">
      </mat-icon>
    </button>
  </div>

</ng-container>
<ng-container *ngIf="(headerPermissions$ | async)"></ng-container>
<mat-menu #cardheader_actions="matMenu">
  <div>
    <button *ngIf="(headerPermissions$ | async)?.edit" mat-menu-item (click)="editCard.emit()">
      <mat-icon>edit</mat-icon>
      Edit
    </button>
    <button *ngIf="(headerPermissions$ | async)?.share" mat-menu-item (click)="shareCard.emit()">
      <mat-icon>share</mat-icon>
      Share
    </button>
    <button *ngIf="(headerPermissions$ | async)?.share" mat-menu-item (click)="copyCardLink.emit()">
      <mat-icon>content_copy</mat-icon>
      Link
    </button>
    <button *ngIf="(headerPermissions$ | async)?.send" mat-menu-item (click)="sendCard.emit()">
      <mat-icon>send</mat-icon>
      Send
    </button>
<!--    <button  *ngIf="(headerPermissions$ | async)?.clone" mat-menu-item (click)="clone.emit()">-->
<!--      <mat-icon>fork_right</mat-icon>-->
<!--      Remix-->
<!--    </button>-->
    <button
      *ngIf="(headerPermissions$ | async)?.delete"
      mat-menu-item
      (click)="delete.emit()"
    >
      <mat-icon>
        delete
      </mat-icon>
      <ng-container *ngIf="!(feedContext?.feedContext$ | async)">
        Delete
      </ng-container>
      <ng-container *ngIf="feedContext?.feedContext$ | async">
        {{(channelService.selectedChannel$ | async)?.id === 'my_cards' ? 'Delete' : 'Remove'}}
      </ng-container>

    </button>
  </div>
</mat-menu>
