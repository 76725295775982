<div class="card" [class.card--edit]="(viewStrat$ | async) !== ViewModeStrategy.Display">
  <app-cardheader
    *ngIf="card!.header"
    [header]="$any(card!.header)"
    (delete)="deleteStack.emit()"
    (editCard)="onEditCard()"
    (sendCard)="send.emit()"
    (shareCard)="share.emit()"
    (copyCardLink)="copyLink.emit()"
    (copyCardInfoToClipboard)="copyCardInfoToClipboard()"
    (clone)="onCloneCard()"
    (createStackMenu)="createStackMenu.emit()"
    class="app-cardheader"
  ></app-cardheader>
  <ng-content></ng-content>
  <app-loading-card-body
    *ngIf="card?.elements"
    [class.hidden]="!isLoading"
  ></app-loading-card-body>
  <app-element-list
    [class.visually-hidden]="isLoading"
    [elements]="card.elements"
    [loadSpotify]="loadSpotify"
    [cardOwnerName]="card.header?.username"
    [cardId]="card.id"
    [cardTitle]="card.header?.title"
    (navigateTo)="onNavigateToCard($event)"
  ></app-element-list>
</div>
