import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {Card, CardElements} from "@mrbeany/stacks_shared";
import {VIEW_MODE} from "../../enum";
import {CardService} from "@app/services/card.service";
import {ChannelsService} from "@app/services/channels/channels.service";
import {ClipboardService} from "@app/services/clipboard.service";
import {CardNavigationService} from "@app/services/card-navigation/card-navigation.service";
import {DialogService} from "@app/services/dialog.service";
import {ShareComponent} from "@app/custom.components/dialogs/share/share.component";
import {MatDialogConfig} from "@angular/material/dialog";
import {CardHeaderActions} from "@mrbeany/stacks_shared/lib/models/card-elements/cardheader";
import {FeedService, isChannelDTO, isNetworkDTO} from "@app/services/feed/feed.service";
import {CardPermissions, ICard} from "@mrbeany/stacks_shared/lib/models/card";
import {SnackbarService} from "@app/services/snackbar.service";
import {environment} from "../../../environments/environment";
import {CardEditorService} from "@app/services/card-editor.service";
import {tap} from "rxjs/operators";
import {StackService} from "@app/card.components/stack/stack.service";
import {ViewModeStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";
import {ShareCardComponent} from "@app/card.components/stack/share-card/share-card.component";
import {FeedContext} from "@app/services/feed-context.service";
import {User, UserRole} from "@app/authentication/user";
import {AuthenticationService} from "@app/authentication/authentication.service";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit, AfterViewInit, OnDestroy {
  private _card!: Card;
  @Input() set card(card: ICard) {
    this._card = Object.assign(new Card(), card);
  }
  get card(): Card {
    return this._card;
  }
  @Input() showDeleteCard = false;
  @Input() loadSpotify = false;
  @Input() isLoading = false;
  @Output() edit = new EventEmitter();
  @Output() navigateTo = new EventEmitter<string>();
  @Output() clone = new EventEmitter();
  @Output() createStackMenu = new EventEmitter();
  @Output() share = new EventEmitter();
  @Output() copyLink = new EventEmitter();
  @Output() send = new EventEmitter();
  @Output() deleteStack = new EventEmitter<string>();
  VIEW_MODE = VIEW_MODE;
  empty = true;
  elementWidth: number | undefined;
  elements = CardElements;
  loadingCard$: any;

  constructor(
    private elementRef: ElementRef,
    private cardService: CardService,
    private cardNavService: CardNavigationService,
    private clipboardService: ClipboardService,
    private dialogService: DialogService,
    private snackbar: SnackbarService,
    private cardEditor: CardEditorService,
    private feedService: FeedService,
    private stackService: StackService,
  ) {
  }

  viewStrat$ = this.stackService.viewStrategy$;
  ngOnInit() {
    this.loadingCard$ = this.cardNavService.loadingCard$
      .pipe(tap((res) => (this.isLoading = res)))
      .subscribe();
  }

  ngOnDestroy() {
    this.loadingCard$.unsubscribe();
  }

  ngAfterViewInit() {
    this.elementWidth = this.elementRef.nativeElement.offsetWidth;
  }

  onEditCard() {
    this.edit.emit();
  }



  copyCardInfoToClipboard() {
    this.clipboardService.setCardInfo({
      cardId: this.card.id,
      cardTitle: this.card.header?.title,
    });
    this.snackbar.showSnackbar("Got Card Link!");
  }

  onNavigateToCard($event: CardNavEvent) {
    if (!$event.link) {
      this.cardEditor.initLinkedCard(this.card, $event);
    }
    this.navigateTo.emit($event.link);
  }




  onCloneCard() {
    this.clone.emit();
  }

  protected readonly ViewModeStrategy = ViewModeStrategy;

}
export function getHeaderActions(
  isPublic: boolean,
  user: User,
  cardOwnerName?: string,
  feedContext?: FeedContext
): CardHeaderActions {
  const isOwner = user ? user.name === cardOwnerName : true;
  const defaultPermissions : CardPermissions = {
    delete: isOwner,
    edit: isOwner,
    share: true,
    report: false,
    copyId: false,
    clone: false,
    send: false
  }
  //todo: instead of checking the user role, check if the user is logged in
  if (isChannelDTO(feedContext)) {
    const permissions = feedContext?.channelRole?.permissions;
    const channelPermissions = {
      ...defaultPermissions,
      delete: isOwner || permissions?.removeCard,
      edit: isOwner,
      share: isPublic || isOwner,
      copyId: isOwner || isPublic,
      send: user.role !== UserRole.Guest,
      clone: user.role !== UserRole.Guest
    };
    return channelPermissions;
  }
  if (isNetworkDTO(feedContext)) {
    const permissions = feedContext?.networkRole?.permissions;
    const networkPermissions = {
      ...defaultPermissions,
      delete: isOwner || permissions?.removeCard,
      edit: isOwner,
      share: isPublic || isOwner,
      copyId: isOwner || isPublic,
      send: user.role !== UserRole.Guest,
      clone: user.role !== UserRole.Guest
    };
    return networkPermissions;
  }
  return defaultPermissions;

}

export interface CardNavEvent {
  link?: string;
  label?: string;
}
