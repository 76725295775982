import {Inject, Injectable} from "@angular/core";
import {BehaviorSubject, catchError, finalize, throwError} from "rxjs";
import {Card, Stack} from "@mrbeany/stacks_shared";
import {CardService} from "@app/services/card.service";
import {map, tap} from "rxjs/operators";
import {IdService} from "@app/services/id.service";
import {ICard} from "@mrbeany/stacks_shared/lib/models/card";
import {createUserCard, createUserStack} from "@app/helpers/helpers";
import {User} from "@app/authentication/user";
import {environment} from "../../../environments/environment";
import {urlJoin} from "@angular-devkit/build-angular/src/utils";

@Injectable()
export class CardNavigationService {
  private history: Array<Stack> = new Array(0);

  private historyPointer = new BehaviorSubject<number>(0);
  historyPointer$ = this.historyPointer.asObservable();

  currCard$ = this.historyPointer$.pipe(map((index) => this.history[index]));

  private loadingCard = new BehaviorSubject<boolean>(false);
  loadingCard$ = this.loadingCard.asObservable();

  canGoForward$ = this.historyPointer$.pipe(
    map((index) => this.canGoForward(index, this.history.length))
  );
  canGoBackward$ = this.historyPointer$.pipe(map((index) => this.canGoBack(index)));
  private ID;

  constructor(
    private cardService: CardService,
    private idService: IdService
  ) {
    this.ID = this.idService.getId("navService");
  }

  init(stack: Stack | undefined) {
    if (stack) {
      this.history = [stack];
    }
  }

  updateCurrentContent(content: Stack) {
    this.history[this.historyPointer.getValue()] = content;
  }

  navigateToStack(link?: string) {
    if (!link) {
      return;
    }
    this.loadingCard.next(true);
    let id = undefined;
    let urlId = undefined;
    //seeing if we have a cardId, a cardId embeded in a url or an reserved urlid
    if (link.includes(`${environment.baseUrlGui}/card/`)) {
      const segments = link.split('/');
      id = segments[segments.length - 1];
    } else if (link.includes(`${environment.baseUrlGui}/`)) {
      const segments = link.split('/');
      urlId = segments[segments.length - 1];
    } else if (!link.includes(`${environment.baseUrlGui}`)) {
      id = link;
    }
    if (id) {
      return this.cardService.getStacks([id]).pipe(
        tap((res) => {
          this.history.splice(this.historyPointer.value + 1, 0, res[0]);
          this.historyPointer.next(this.historyPointer.value + 1);
        }),
        catchError((err) => {
          {
            if (err.status === 403 || err.status === 401) {
              this.history.splice(this.historyPointer.value + 1, 0, err.response.data);
            }
          }
          return throwError(() => err);
        }),
        finalize(() => {
          this.loadingCard.next(false);
        })
      );
    }
    if(urlId) {
      return this.cardService.getContentByUrl(urlId).pipe(
        tap((res: any) => {
          this.history.splice(this.historyPointer.value + 1, 0, res);
          this.historyPointer.next(this.historyPointer.value + 1);
        }),
        catchError((err) => {
          {
            if (err.status === 403 || err.status === 401) {
              this.history.splice(this.historyPointer.value + 1, 0, err.response.data);
            }
          }
          return throwError(() => err);
        }),
        finalize(() => {
          this.loadingCard.next(false);
        })
      );
    }
    return;
  }

  canGoForward(index: number, length: number) {
    return index < length - 1;
  }
  canGoBack(index: number) {
    return index > 0;
  }

  goBack() {
    const currIndex = this.historyPointer.value;
    if (currIndex > 0) {
      this.historyPointer.next(currIndex - 1);
    }
  }

  goForward() {
    const currIndex = this.historyPointer.value;
    if (currIndex < this.history.length - 1) {
      this.historyPointer.next(currIndex + 1);
    }
  }
}
