import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {Card, CardElements, Stack} from "@mrbeany/stacks_shared";
import {VIEW_MODE} from "../../../enum";
import {ChannelsService} from "@app/services/channels/channels.service";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {map, mergeMap, tap} from "rxjs/operators";
import {catchError, finalize, Observable, throwError} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {SnackbarService} from "@app/services/snackbar.service";
import {HttpErrorResponse} from "@angular/common/http";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {FeedService} from "@app/services/feed/feed.service";
import {NetworkService} from "@app/services/network.service";

// @ts-ignore
import {Button} from "@mrbeany/stacks_shared/lib/models/card-elements/button";
import {CardEditorService} from "@app/services/card-editor.service";
import {AppService} from "@app/services/navbar/app.service";
import {ViewModeStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";

@Component({
  selector: "app-feed",
  templateUrl: "./feed.component.html",
  styleUrls: ["./feed.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() showCreateCard: boolean | undefined = false;
  @Input() loadingNextPage = false;
  @Input() layout?: string | null = "feed";

  @Output() cancelCreateCard = new EventEmitter<void>();
  @ViewChild(CdkVirtualScrollViewport)
  virtualScrollViewport!: CdkVirtualScrollViewport;

  CARD_ELEMENT_TYPE = CardElements.CARD_ELEMENT_TYPE;
  VIEW_MODE = VIEW_MODE;
  feed$ = this.feedService.feed$;
  Card = Card;
  Stack = Stack;
  joiningChannel = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.TabletPortrait, Breakpoints.WebPortrait])
    .pipe(map((result) => result.matches));
  channelInvite$: Observable<any> | undefined;
  createrStack$!: Observable<Stack>;
  channelRole$ = this.channelService.selectedChannel$.pipe(map((channel) => channel?.channelRole));
  channelName$ = this.channelService.selectedChannel$.pipe(map((channel) => channel?.channelName));
  columns$ = this.appService.width$.pipe(
    map((width) => {
      if (width > 1919) {
        return 3;
      }
      if (width >= 768) {
        return 2;
      } else {
        return 1;
      }
    })
  );
  trackByFn = (index: number, item: any) => item.id;

  constructor(
    private channelService: ChannelsService,
    private networkService: NetworkService,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService,
    private breakpointObserver: BreakpointObserver,
    private feedService: FeedService,
    private renderer: Renderer2,
    private cardEditor: CardEditorService,
    private appService: AppService
  ) {
    this.channelInvite$ = this.route.queryParams.pipe(
      map((res) => {
        if (res["invitation"]) {
          return {
            channelId: res["channel"] as string,
            inviteKey: res["invitation"] as string,
          };
        }
        return undefined;
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit() {
    // Simulate asynchronous data load
    setTimeout(() => {
      if (this.virtualScrollViewport) {
        const nativeElement = this.virtualScrollViewport.elementRef.nativeElement;
        nativeElement.scrollTop = 0;
      }
    }, 0); // Simulate data load delay
  }

  acceptChannelInvite(channelId: string, inviteKey: string) {
    this.joiningChannel = true;
    this.channelService
      .joinChannel({
        key: inviteKey,
        channelId: channelId,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status !== 200) {
            this.snackbarService.showSnackbar("An Error occured, please try again later.");
          }
          return throwError(() => err);
        }),
        mergeMap(() => {
          return this.channelService.updateUserChannels().pipe(
            tap(() => {
              this.channelService.selectChannel(channelId);
            })
          );
        }),
        finalize(() => (this.joiningChannel = false))
      )
      .subscribe();
  }

  onCancelCardCreator() {
    this.cancelCreateCard.emit();
    this.showCreateCard = false;
  }

  loadNextPage() {
    this.loadingNextPage = true;
    if (this.channelService.getSelectedChannelSnapshot()) {
      this.channelService
        .nextPage(calculateTake(this.channelService.getSelectedChannelSnapshot()?.config?.layout))
        .pipe(
          finalize(() => {
            this.loadingNextPage = false;
          })
        )
        .subscribe();
      return;
    }
    if (this.networkService.getSelectedNetworkSnapshot()) {
      this.networkService
        .nextPage(calculateTake(this.networkService.getSelectedNetworkSnapshot()?.config?.layout))
        .pipe(
          finalize(() => {
            this.loadingNextPage = false;
          })
        )
        .subscribe();
    }
  }

  scrollToCard(el: HTMLDivElement) {
    const virtualScollContainer = document.getElementsByClassName("mat-mdc-tab-body-content")[1];
    virtualScollContainer.scrollTo({top: el.offsetTop - 80, behavior: "smooth"});
  }

  scrollToTop() {
    const virtualScollContainer = document.getElementsByClassName("mat-mdc-tab-body-content")[1];
    virtualScollContainer.scrollTo({top: 0, behavior: "smooth"});
  }

  onNaviagteTo($event: string, stackRef: HTMLDivElement) {
    const id = $event;
    if (!id) {
      this.scrollToTop();
      this.showCreateCard = true;
    } else {
      this.scrollToCard(stackRef);
    }
  }

  protected readonly ViewModeStrategy = ViewModeStrategy;
}

export function calculateTake(layout?: 'feed' | 'grid' | 'masonry' | undefined) : number {
  let take = 15;
  if(!layout) {
    return take;
  }
  if(layout === "feed") {
    return take;
  }
  if(layout === 'masonry' || layout === 'grid') {
    const feed = window.innerWidth;
    if(!feed) {
      return take;
    }
    if(window.innerWidth < 1024) {
      take = 20;
    } else {
      take = 30;
    }
  }
  return take;
}

