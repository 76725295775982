<app-card-element
  #cardElementContainerRef
  *ngFor="let element of elements; let i = index"
  (delete)="onRemoveElement(i)"
  (moveUp)="onMoveUp(i)"
  (moveDown)="onMoveDown(i)"
  (edit)="editElement(i)"
  (confirm)="confirmChanges(i)"
  [class.app-card-element--edit]="cardElementContainerRef.showEditActions"
  [class.app-gallery]="element?.type === CARD_ELEMENT_TYPE.GALLERY"
  [class.app-text]="element?.type === CARD_ELEMENT_TYPE.TEXT"
  [class.app-expansion-panel]="element?.type === CARD_ELEMENT_TYPE.EXPANSION_PANEL"
  [class.app-quill]="element?.type === CARD_ELEMENT_TYPE.QUILL"
>
  <ng-container
    [ngSwitch]="element?.type"
  >
    <app-product-element
      #cardElementRef
      *ngSwitchCase="CARD_ELEMENT_TYPE.PRODUCT"
      [product]="$any(element)"
      (navigateTo)="onNavigateToCard($event)"
    ></app-product-element>
    <app-hyperlink
      #cardElementRef
      (navigateTo)="onNavigateToCard($event)"
      *ngSwitchCase="CARD_ELEMENT_TYPE.HYPERLINK"
      [hyperlink]="$any(element)"
    >
    </app-hyperlink>
    <app-socials
      #cardElementRef
      *ngSwitchCase="CARD_ELEMENT_TYPE.SOCIALS"
      [social]="$any(element)"
    >
    </app-socials>
    <app-email-sub
      #cardElementRef
      *ngSwitchCase="CARD_ELEMENT_TYPE.EMAIL_SUB"
      [emailSubElement]="$any(element)"
      [cardId]="cardId"
      [cardTitle]="cardTitle"
      [cardOwnerName]="cardOwnerName"
    >
    </app-email-sub>
    <app-gallery
      #cardElementRef
      *ngSwitchCase="CARD_ELEMENT_TYPE.GALLERY"
      [gallery]="$any(element)"
    ></app-gallery>
    <app-card-chat
      #cardElementRef
      *ngSwitchCase="CARD_ELEMENT_TYPE.CHAT"
    ></app-card-chat>
    <app-file
      #cardElementRef
      *ngSwitchCase="CARD_ELEMENT_TYPE.FILE"
      [file]="$any(element)"
    >
    </app-file>
    <app-text
      *ngSwitchCase="CARD_ELEMENT_TYPE.TEXT"
      #cardElementRef
      (navigateTo)="onNavigateToCard($event)"
      [text]="$any(element) | signalify"
    >
    </app-text>
    <app-quill
      #cardElementRef
      *ngSwitchCase="CARD_ELEMENT_TYPE.QUILL"
      [quillData]="$any(element)"
    >

    </app-quill>
    <app-expansion-panel
      *ngSwitchCase="CARD_ELEMENT_TYPE.EXPANSION_PANEL"
      #cardElementRef
      [expansionPanel]="$any(element) | objectAssign"
      (navigateTo)="onNavigateToCard($event)"
      [title]="$any(element).title"
    >
      <input
        (keydown)="$event.stopPropagation()"
        matInput
        type="text"
        placeholder="Dropdown Title"
        [(ngModel)]="$any(element).title"
      />
    </app-expansion-panel>
    <app-spotify
      *ngSwitchCase="CARD_ELEMENT_TYPE.SPOTIFY"
      [spotify]="$any(element)"
      [loadSpofify]="loadSpotify"
      #cardElementRef

    >
    </app-spotify>
    <app-button-container
      *ngSwitchCase="CARD_ELEMENT_TYPE.BUTTON_CONTAINER"
      #cardElementRef
      [owner]="cardOwnerName"
      [buttonContainer]="$any(element)"
      (navigateTo)="onNavigateToCard($event)"
    >
    </app-button-container>
  </ng-container>

</app-card-element>
<app-card-element>
  <app-placeholder-element class="pointer" type="button" role="button" [matMenuTriggerFor]="elementsMenu" mat-ripple  *ngIf="(viewStrat$ | async) !== ViewModeStrategy.Display" >
    <div class="add-element-container">
      <mat-icon height="38">add</mat-icon>
      <span *ngIf="elements.length < 1"> Add an element to get started </span>
    </div>
  </app-placeholder-element>
</app-card-element>

<mat-menu #elementsMenu="matMenu">
  <div class="element-menu-wrapper">
    <button
      *ngIf="!excludeElements?.includes(CARD_ELEMENT_TYPE.QUILL)"
      mat-menu-item
      class="icon-button text-button"
      (click)="onAddQuill()"
    >
      <mat-icon>edit</mat-icon>
      <span>Text</span>
    </button>
    <button
      *ngIf="!excludeElements?.includes(CARD_ELEMENT_TYPE.GALLERY)"
      mat-menu-item
      class="full-icon-button gallery-button"
      (click)="onAddGallery()"
    >
      <mat-icon>photo</mat-icon>
      Images
    </button>
    <button
      *ngIf="!excludeElements?.includes(CARD_ELEMENT_TYPE.HYPERLINK)"
      mat-menu-item
      class="full-icon-button hyperlink-button"
      (click)="onAddHyperlink()"
    >
      <mat-icon>link</mat-icon>
      Link
    </button>
    <button
      *ngIf="!excludeElements?.includes(CARD_ELEMENT_TYPE.PRODUCT)"
      mat-menu-item
      class="full-icon-button product-button"
      (click)="onAddProduct()"
    >
      <mat-icon>sell</mat-icon>
      Product
    </button>
    <button
      *ngIf="!excludeElements?.includes(CARD_ELEMENT_TYPE.EXPANSION_PANEL)"
      mat-menu-item
      class="icon-button expansion-button"
      (click)="onAddExpansionPanel()"
    >
      <mat-icon>expand_circle_down</mat-icon>
      <span>Dropdown</span>
    </button>
    <button
      *ngIf="!excludeElements?.includes(CARD_ELEMENT_TYPE.EMAIL_SUB)"
      mat-menu-item
      class="full-icon-button socials-button"
      (click)="onAddSocials()"
    >
      <mat-icon>groups</mat-icon>
      Social Links
    </button>
<!--    <button-->
<!--      *ngIf="!excludeElements?.includes(CARD_ELEMENT_TYPE.BUTTON_CONTAINER) && (viewStrat$ | async) !== ViewModeStrategy.Template"-->
<!--      mat-menu-item-->
<!--      class="full-icon-button link-button"-->
<!--      (click)="onAddButtonContainer()"-->
<!--    >-->
<!--      <mat-icon class="rotate45">route</mat-icon>-->
<!--      Connect-->
<!--    </button>-->
    <button
      *ngIf="!excludeElements?.includes(CARD_ELEMENT_TYPE.SPOTIFY)"
      mat-menu-item
      class="full-icon-button spotify-button"
      (click)="onAddSpotify()"
    >
      <mat-icon>music_note</mat-icon>
      Spotify
    </button>
    <button
      *ngIf="!excludeElements?.includes(CARD_ELEMENT_TYPE.EMAIL_SUB)"
      mat-menu-item
      class="full-icon-button email-sub-button"
      (click)="onAddEmailSub()"
    >
      <mat-icon>mail</mat-icon>
      Newsletter
    </button>

    <!--  <button
        *ngIf="!excludeElements?.includes(CARD_ELEMENT_TYPE.CHAT)"
        mat-menu-item
        class="full-icon-button chat-button"
        (click)="onAddChat()"
      >
        <mat-icon>chat</mat-icon>
        Chat
      </button>-->
  </div>
</mat-menu>
